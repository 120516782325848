import { AxiosRequestConfig } from "axios";
import { callExternalApi } from "./external-api.service";
import { ApiResponse } from "src/models/api-response";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getAgent = async (
  accessToken: string,
  userId: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/agents?user_id=${userId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};
