import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from 'dayjs';

import { Button, DatePicker, Form, InputNumber, Select, Space, Progress, Upload, Typography } from 'antd';
import { FileOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';

import { downloadFundFile, uploadFundFile } from 'src/services/offer.service';
import Constants from 'src/util/constants';

interface Props {
    loanType: string;
    closingDate: string;
    downPaymentPercentage: number;
    handleLoanTypeChange: (e: any) => void;
    handleClosingDateChange: (e: any) => void;
    handleDownPaymentPercentageChange: (e: any) => void;
    onComplete: () => void;
    onBack: () => void;
}

export const LoanInfo: React.FC<Props> = ({
    loanType,
    closingDate,
    downPaymentPercentage,
    handleLoanTypeChange,
    handleClosingDateChange,
    handleDownPaymentPercentageChange,
    onComplete,
    onBack,
}) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const { Dragger } = Upload;
    const [file, setFile] = useState<File | null>();
    const [uploadProgres, setUploadProgress] = useState<number>(0);
    const [fileError, setFileError] = useState<string | null>();
    const [isCheckingExitingFundFile, setIsCheckingExitingFundFile] = useState(true);
    const [existingFundFile, setExistingFundFile] = useState<any>();
    const [fundFileURL, setFundFileURL] = useState();


    useEffect(() => {
        let isMounted = true;

        const download = async () => {
            const accessToken = await getAccessTokenSilently();
            const userId = user == null ? '' : user.usb === null ? '' : user.sub;
            const { data, error } = await downloadFundFile(accessToken, userId ?? '');
            setIsCheckingExitingFundFile(false);
            if (data) {
                // consider save file name separately so that we don't need to get the entire file to get the file name.
                setFundFileURL(data.base64);
                setExistingFundFile(data);
            }

            if (error) {
                console.log(error);
            }
        };

        download();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleUploadFundFile = (file: File) => {
        if (file.name.length > Constants.MAX_FILE_NAME_LENGTH) {
            setFileError('File name is too long.');
        } else if (file.size > Constants.MAX_FILE_SIZE) {
            setFileError('File is too large. Max file size is 10MB.');
        } else if (!Constants.getAllowedFileTypes().includes(file.type)) {
            setFileError('Only support the following file types: ' + Constants.getAllowedFileTypeNames().join(', '));
        } else {
            setFileError(null);
        }

        const onUploadProgress = (e: any) => {
            const progress = Number(e.loaded / e.total);
            setUploadProgress(Number(progress.toFixed(2)));
        };

        const upload = async (base64File: any) => {
            const accessToken = await getAccessTokenSilently();
            const userId = user == null ? '' : user.usb === null ? '' : user.sub;
            const payload = {
                fileName: file.name,
                date: dayjs().format(Constants.DATE_FORMAT),
                base64: base64File,
            }

            const { data, error } = await uploadFundFile(accessToken, userId ?? '', JSON.stringify(payload), onUploadProgress);
            if (data != null) {
                setExistingFundFile(undefined);
                setFundFileURL(base64File);
            }

            if (error) {
                setFileError('Failed to upload file. Please try again.');
            }
        };

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setFile(file);
            upload(reader.result);
        };
        reader.onerror = function (error) {
            setFileError('Failed to upload file. Please try again.');
        };
    };

    const uploadProps = {
        name: 'fundFile',
        multiple: false,
        action: '', // upload URL
        showUploadList: false,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                console.log(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                console.log(`${info.file.name} file upload failed.`);
            }
        },
        customRequest(info: any) {
            handleUploadFundFile(info.file);
        },
    };

    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    let initialClosingDate;
    if (closingDate) {
        initialClosingDate = dayjs(closingDate);
    } else {
        initialClosingDate = dayjs().add(35, 'day');
        handleClosingDateChange(initialClosingDate.format(Constants.DATE_FORMAT));
    }

    const getFileType = () => {
        return loanType === 'cash' ? 'proof of funds' : 'pre-approval letter';
    };

    const getFileUploadStatus = () => {
        if (isCheckingExitingFundFile) {
            return (
                <Space>
                    <LoadingOutlined />
                    <Typography>
                        Checking existing fund file.
                    </Typography>
                </Space>
            );
        } else if (existingFundFile) {
            return (
                <Space direction='vertical' style={{
                    width: '100%',
                    padding: '10px',
                }}>
                    <Typography>
                        You have uploaded a {getFileType()} previously. Click to download.
                    </Typography>
                    {dayjs(existingFundFile.date).isBefore(dayjs().subtract(2, 'M')) &&
                        <Typography>
                            The file is more than 2 month old. You may need to upload a new one.
                        </Typography>}
                    <Space>
                        <FileOutlined />
                        <a download={existingFundFile.fileName} href={fundFileURL}>{existingFundFile.fileName}</a>
                    </Space>
                </Space>
            );
        } else if (fileError) {
            return (
                <Typography>
                    {fileError}
                </Typography>
            );
        } else if (file) {
            return (
                <Space direction='vertical' style={{
                    width: '100%',
                    padding: '10px',
                }}>
                    <Space>
                        <FileOutlined />
                        <Typography>
                            <a download={file.name} href={fundFileURL}>{file.name}</a>
                        </Typography>
                    </Space>
                    <Progress percent={uploadProgres * 100} />
                </Space>
            );
        } else {
            return (
                <Typography>
                    {`Upload your ${getFileType()} here`}
                </Typography>
            );
        }
    }

    return (
        <Form
            name='loan-info'
            onFinish={onComplete}
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                loanType: loanType,
                closingData: initialClosingDate,
                downPaymentPercentage: downPaymentPercentage
            }}
            autoComplete='off'
        >
            <Form.Item
                name='loanType'
                label='Loan type'
                rules={[
                    {
                        required: true,
                        message: 'Required field',
                    },
                ]}
            >
                <Select
                    placeholder=''
                    onChange={handleLoanTypeChange}
                    options={[
                        {
                            value: 'conventionalFirst',
                            label: 'Conventional First',
                        },
                        {
                            value: 'conventionalSecond',
                            label: 'Conventional Second',
                        },
                        {
                            value: 'VA',
                            label: 'VA',
                        },
                        {
                            value: 'FHA',
                            label: 'FHA',
                        },
                        {
                            value: 'cash',
                            label: 'Cash',
                        },
                    ]}
                />
            </Form.Item>

            <Form.Item
                name='closingData'
                label='Closing date'
                rules={[
                    {
                        required: true,
                        message: 'Closing date is required',
                    },
                ]}
            >
                <DatePicker onChange={(e: any) => {
                    handleClosingDateChange(dayjs(e).format(Constants.DATE_FORMAT));
                }} />
            </Form.Item>

            <Form.Item
                name="downPaymentPercentage"
                label="Down payment percentage"
            >
                <InputNumber
                    style={{
                        width: '137px',
                    }}
                    min={0}
                    max={100}
                    onChange={handleDownPaymentPercentageChange} />
            </Form.Item>

            <Form.Item
                name='upload'
                label={loanType === 'cash' ? 'Proof of funds' : 'Pre-approval letter'}
                rules={[
                    {
                        required: true,
                        validator: () =>
                            (existingFundFile && existingFundFile.fileName != '') || uploadProgres == 1 ?
                                Promise.resolve() : Promise.reject(new Error('This is required')),
                    },
                ]}
            >
                <Space direction='vertical' style={{
                    width: '100%'
                }}>
                    <Dragger {...uploadProps}>
                        <p>
                            <UploadOutlined />
                        </p>
                        <p>Click or drag file to this area to upload</p>
                    </Dragger>
                    {getFileUploadStatus()}
                </Space>
            </Form.Item>



            <Form.Item {...tailLayout}>
                <Space className='form-button-group'>
                    <Button type='primary' htmlType='submit'>
                        Submit
                    </Button>
                    <Button htmlType='button' onClick={onBack}>
                        Back
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
