import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { List, Table } from 'antd';

import Utils from '../util/utilities';
import { Offer } from "src/models/offer";
import { getOffersByUser, downloadOfferFile } from "src/services/offer.service";

interface Props {
    setOffer: (offer: Offer) => void;
}

export const OfferList: React.FC<Props> = ({
    setOffer,
}) => {

    const { user, getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        let isMounted = true;

        const loadOffers = async () => {
            const accessToken = await getAccessTokenSilently();
            const userId = user == null ? '' : user.usb === null ? '' : user.sub;
            const { data, error } = await getOffersByUser(accessToken, userId ?? '');

            if (!isMounted) {
                return;
            }

            setIsLoading(false);

            if (data) {
                setOffers(data);

            }

            if (error) {
                // TODO: set error state
                setOffers([]);
            }
        };

        loadOffers();

        return () => {
            isMounted = false;
        };
    }, []);

    const downloadOffer = (offerDownloadLink: string) => {
        const download = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await downloadOfferFile(accessToken, offerDownloadLink);
            if (data) {
                const link = document.createElement('a');
                link.href = data.base64;
                link.setAttribute('download', data.fileName);
                 document.body.appendChild(link);
                link.click();
                link.remove();
            }

            if (error) {
                console.log(error);
            }
        };

        download();
    };

    const columns = [
        {
            title: 'Property address',
            dataIndex: 'propertyAddress',
        },
        {
            title: 'Purchase price',
            dataIndex: 'purchasePrice',
        },
        {
            title: 'Offer status',
            dataIndex: 'offerStatus',
        },
        {
            title: 'Download offer',
            dataIndex: 'offerDownloadLink',
            render: (_: any, record: any) => {
                return record.offerDownloadLink == null ? 'not ready' : <a onClick={() => downloadOffer(record.fullOffer.offerDownloadLink)}>Download</a>;
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (_: any, record: any) => <a onClick={() => setOffer(record.fullOffer)}>Edit</a>,
        },
    ];

    const offerRows: any = [];
    if (!isLoading) {
        offers.forEach((item: Offer) => {
            offerRows.push({
                key: item.offerId,
                propertyAddress: item.propertyAddress,
                purchasePrice: Utils.dollarFormatter.format(item.purchasePrice),
                offerStatus: item.offerDownloadLink != null ? 'Offer ready' : 'pending',
                offerDownloadLink: item.offerDownloadLink,
                fullOffer: item,
            });
        });
    }

    const tableStyle = {
        padding: 24,
    };

    const expandableStyle = {
        paddingLeft: 48,
        paddingRight: 48,
    };

    return (<Table
        size="small"
        loading={isLoading}
        columns={columns}
        dataSource={offerRows}
        style={tableStyle}
        expandable={{
            expandedRowRender: (record) => {
                const data = [
                    {
                        title: 'Offer date',
                        description: record.fullOffer.offerDate,
                    },
                    {
                        title: 'Offer expiration date',
                        description: record.fullOffer.offerExpirationDate,
                    },
                    {
                        title: 'Earnest money amount',
                        description: Utils.dollarFormatter.format(record.fullOffer.earnestMoneyAmount),
                    },
                    {
                        title: 'Earnest money delivery days',
                        description: record.fullOffer.earnestMoneyDeliveryDays,
                    },
                    {
                        title: 'Loan type',
                        description: record.fullOffer.loanType,
                    },
                    {
                        title: 'Closing date',
                        description: record.fullOffer.closingDate,
                    },
                    {
                        title: 'Down payment percentage',
                        description: record.fullOffer.downPaymentPercentage,
                    },
                    {
                        title: 'Third party link',
                        description: record.fullOffer.thirdPartyLink == '' ? 'N/A' : record.fullOffer.thirdPartyLink,
                    },
                ];

                return (
                    <List
                        style={expandableStyle}
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.title}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                );
            },
        }}
    />);

};
