import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { useAuth0 } from "@auth0/auth0-react";
import { Descriptions, Progress, Space, Table, Upload, Typography } from 'antd';
import type { DescriptionsProps } from 'antd';
import { FileOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { AppLayout } from "../components/app-layout";

import { getOffersByAgent } from "src/services/offer.service";
import { downloadOfferFile, uploadOfferByAgent } from 'src/services/offer.service';
import { Offer } from "src/models/offer";
import Utils from '../util/utilities';
import Constants from 'src/util/constants';

export const AgentOffersPage: React.FC = () => {
    const { Dragger } = Upload;
    const { user, getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [offers, setOffers] = useState<Map<string, Offer>>(new Map());
    const [uploadProgreses, setUploadProgresses] = useState<Map<string, number>>(new Map());
    const [files, setFiles] = useState<Map<string, File>>(new Map());
    const [fileURLs, setFileURLs] = useState<Map<string, string>>(new Map());
    const [fileErrors, setFileErrors] = useState<Map<string, string | null>>(new Map());
    const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

    useEffect(() => {
        let isMounted = true;

        const loadOffers = async () => {
            const accessToken = await getAccessTokenSilently();
            const userId = user == null ? '' : user.usb === null ? '' : user.sub;
            const { data, error } = await getOffersByAgent(accessToken, userId ?? '');

            if (!isMounted) {
                return;
            }

            setIsLoading(false);

            if (data) {
                const offerMap = new Map();
                const uploadMap = new Map();
                const errorMap = new Map();
                data.forEach((e: Offer) => {
                    offerMap.set(e.offerId, e);
                    uploadMap.set(e.offerId, 0);
                    errorMap.set(e.offerId, null);
                });
                setOffers(offerMap);
                setUploadProgresses(uploadMap);
                setFileErrors(errorMap);

            }

            if (error) {
                // TODO: set error state
                setOffers(new Map());
                setUploadProgresses(new Map());
                setFileErrors(new Map());
            }
        };

        loadOffers();

        return () => {
            isMounted = false;
        };
    }, []);

    if (!user) {
        return null;
    }

    const downloadOffer = (offerId: string) => {
        const download = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await downloadOfferFile(accessToken, offerId);
            if (data) {
                const link = document.createElement('a');
                link.href = data.base64;
                link.setAttribute('download', data.fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }

            if (error) {
                console.log(error);
            }
        };

        download();
    };

    const columns = [
        {
            title: 'Buyer name',
            dataIndex: 'buyerName',
        },
        {
            title: 'Property address',
            dataIndex: 'propertyAddress',
        },
        {
            title: 'Offer status',
            dataIndex: 'offerStatus',
        },
        {
            title: 'Download offer',
            dataIndex: 'offerDownloadLink',
            render: (_: any, record: any) => {
                return record.offerDownloadLink == null ? 'not ready' : <a onClick={() => downloadOffer(record.offerDownloadLink)}>Download</a>;
            },
        },
    ];

    const offerRows: any = [];
    if (!isLoading) {
        offers.forEach((value, key) => {
            offerRows.push({
                key: key,
                buyerName: value.firstBuyerName,
                propertyAddress: value.propertyAddress,
                offerStatus: value.offerDownloadLink != null ? 'Offer ready' : 'pending',
                offerDownloadLink: value.offerDownloadLink,
                fullOffer: value,
            });
        });
    }

    const tableStyle = {
        padding: 24,
    };

    const handleUploadFundFile = (offerId: string, file: File) => {
        if (offerId == null) {
            const newMap = new Map(fileErrors);
            newMap.set(offerId, 'Missing offer id.');
            setFileErrors(newMap);
        }
        else if (file.name.length > Constants.MAX_FILE_NAME_LENGTH) {
            const newMap = new Map(fileErrors);
            newMap.set(offerId, 'File name is too long.');
            setFileErrors(newMap);
        } else if (file.size > Constants.MAX_FILE_SIZE) {
            const newMap = new Map(fileErrors);
            newMap.set(offerId, 'File is too large. Max file size is 10MB.');
            setFileErrors(newMap);
        } else if (!Constants.getAllowedFileTypes().includes(file.type)) {
            const newMap = new Map(fileErrors);
            newMap.set(offerId, 'Only support the following file types: ' + Constants.getAllowedFileTypeNames().join(', '));
            setFileErrors(newMap);
        } else {
            const newMap = new Map(fileErrors);
            newMap.set(offerId, null);
            setFileErrors(newMap);
        }

        const onUploadProgress = (e: any) => {
            const progress = Number(e.loaded / e.total);
            const newMap = new Map(uploadProgreses);
            newMap.set(offerId, Number(progress.toFixed(2)));
            setUploadProgresses(newMap);
        };

        const upload = async (base64File: any) => {
            const accessToken = await getAccessTokenSilently();
            const userId = user == null ? '' : user.usb === null ? '' : user.sub;
            const payload = {
                fileName: file.name,
                date: dayjs().format(Constants.DATE_FORMAT),
                base64: base64File,
            }

            const { data, error } = await uploadOfferByAgent(accessToken, offerId, JSON.stringify(payload), onUploadProgress);
            if (data != null) {
                const newMap = new Map(offers);
                newMap.set(offerId, data);
                setOffers(newMap);

                const newFileURLMap = new Map(fileURLs);
                newFileURLMap.set(offerId, base64File);
                setFileURLs(newFileURLMap);
            }

            if (error) {
                const newMap = new Map(fileErrors);
                newMap.set(offerId, 'Failed to upload file. Please try again.');
                setFileErrors(newMap);
            }
        };

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const newMap = new Map(files);
            newMap.set(offerId, file);
            setFiles(newMap);
            upload(reader.result);
        };
        reader.onerror = function (error) {
            const newMap = new Map(fileErrors);
            newMap.set(offerId, 'Failed to upload file. Please try again.');
            setFileErrors(newMap);
        };
    };

    const getUploadPrps = (offerId: string) => {
        return {
            name: 'fundFile',
            multiple: false,
            action: '', // upload URL
            showUploadList: false,
            onChange(info: any) {
                const { status } = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    console.log(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    console.log(`${info.file.name} file upload failed.`);
                }
            },
            customRequest(info: any) {
                handleUploadFundFile(offerId, info.file);
            },
        };
    };

    const getFileUploadStatus = (offerId: string) => {
        let fileError;
        if (fileErrors != null) {
            fileError = fileErrors.get(offerId);
        }

        let uploadProgress = 0;
        if (uploadProgreses != null) {
            uploadProgress = uploadProgreses.get(offerId) ?? 0;
        }

        let file;
        if (files != null) {
            file = files.get(offerId);
        }

        let fileURL;
        if (fileURLs != null) {
            fileURL = fileURLs.get(offerId);
        }

        if (fileError) {
            return (
                <Typography>
                    {fileError}
                </Typography>
            );
        } else if (file) {
            return (
                <Space direction='vertical' style={{
                    width: '100%',
                    padding: '10px',
                }}>
                    <Space>
                        <FileOutlined />
                        <Typography>
                            <a download={file.name} href={fileURL}>{file.name}</a>
                        </Typography>
                    </Space>
                    <Progress percent={uploadProgress * 100} />
                </Space>
            );
        } else {
            return (
                <Typography>
                    {`Upload offer here`}
                </Typography>
            );
        }
    }

    return (
        <AppLayout route="agent-offers">
            <Table
                size="small"
                loading={isLoading}
                columns={columns}
                dataSource={offerRows}
                style={tableStyle}
                expandable={{
                    expandedRowRender: (record) => {
                        const items: DescriptionsProps['items'] = [
                            {
                                key: 1,
                                label: 'First buyer name',
                                children: record.fullOffer.firstBuyerName,
                            },
                            {
                                key: 2,
                                label: 'First buyer email',
                                children: record.fullOffer.firstBuyerEmail,
                            },
                            {
                                key: 3,
                                label: 'First buyer phone number',
                                children: record.fullOffer.firstBuyerPhoneNumber,
                            },
                            {
                                key: 4,
                                label: 'Second buyer name',
                                children: record.fullOffer.secondBuyerName,
                            },
                            {
                                key: 5,
                                label: 'Second buyer email',
                                children: record.fullOffer.secondBuyerEmail,
                            },
                            {
                                key: 6,
                                label: 'Marriage status',
                                children: record.fullOffer.marriageStatus,
                            },
                            {
                                key: 7,
                                label: 'Third party link',
                                children: record.fullOffer.thirdPartyLink,
                            },
                            {
                                key: 8,
                                label: 'Offer date',
                                children: record.fullOffer.offerDate,
                            },
                            {
                                key: 9,
                                label: 'Offer expiration date',
                                children: record.fullOffer.offerExpirationDate,
                            },
                            {
                                key: 10,
                                label: 'Purcahse price',
                                children: Utils.dollarFormatter.format(record.fullOffer.purchasePrice),
                            },
                            {
                                key: 11,
                                label: 'Earnest money',
                                children: Utils.dollarFormatter.format(record.fullOffer.earnestMoneyAmount),
                            },
                            {
                                key: 12,
                                label: 'Earnest money delivery days',
                                children: record.fullOffer.earnestMoneyDeliveryDays,
                            },
                            {
                                key: 13,
                                label: 'Loan type',
                                children: record.fullOffer.loanType,
                            },
                            {
                                key: 14,
                                label: 'Closing date',
                                children: record.fullOffer.closingDate,
                            },
                            {
                                key: 15,
                                label: 'Down payment percentage',
                                children: record.fullOffer.downPaymentPercentage,
                            },
                        ];

                        return (
                            <Space direction='vertical' style={{
                                width: '90%',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}>
                                <Descriptions column={isTabletOrMobile ? 1 : 2} title="Offer details" items={items} />
                                <Space direction='vertical' style={{
                                    width: '100%'
                                }}>
                                    <Dragger {...getUploadPrps(record.fullOffer.offerId)}>
                                        <p>
                                            <UploadOutlined />
                                        </p>
                                        <p>Click or drag file to this area to upload</p>
                                    </Dragger>
                                    {getFileUploadStatus(record.fullOffer.offerId)}
                                </Space>
                            </Space>
                        );
                    },
                }}
            />
        </AppLayout>
    );
};
