import React from "react";

import { useMediaQuery } from "react-responsive";
import { DesktopAppHeader } from "./desktop-app-header";
import { MobileAppHeader } from "./mobile-app-header";

interface Props {
    route?: string;
}

export const AppHeader: React.FC<Props> = ({ route }) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    return (
        <div> {isTabletOrMobile ? <MobileAppHeader route={route} /> : <DesktopAppHeader route={route} />} </div>
    );
};
