import React from "react";

import { Layout } from 'antd';
import { useMediaQuery } from "react-responsive";
import { AppHeader } from "./navigation/app-header";

interface Props {
    children: JSX.Element;
    route?: string;
}

export const AppLayout: React.FC<Props> = ({ children, route }) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

    const layoutStyle = {
        height: '100vh',
        backgroundColor: 'transparent',
    };

    return (
        <Layout style={layoutStyle}>
            <AppHeader route={route} />
            {children}
        </Layout>
    );
};
