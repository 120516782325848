import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Layout, Menu } from 'antd';
import { Brand } from "./brand";
import { LogoutButton } from "../buttons/logout-button";
import { LoginButton } from "../buttons/login-button";
import { SignupButton } from "../buttons/signup-button";

import { getAgent } from "src/services/agent.service";

interface Props {
    route?: string;
}

export const DesktopAppHeader: React.FC<Props> = ({ route }) => {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [isAgent, setIsAgent] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const loadAgent = async () => {
            const accessToken = await getAccessTokenSilently();
            const userId = user == null ? '' : user.usb === null ? '' : user.sub;
            const { data, error } = await getAgent(accessToken, userId ?? '');

            if (!isMounted) {
                return;
            }

            if (data) {
                setIsAgent(true);
            }

            if (error) {
                // TODO: set error state
            }
        };

        loadAgent();

        return () => {
            isMounted = false;
        };
    }, []);

    const { Header } = Layout;
    const headerStyle = {
        height: 80,
        paddingInline: 48,
        lineHeight: '80px',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    };

    const items = [
        {
            key: 'home',
            label: (
                <a href='/'>
                  Home
                </a>
              ),
        },
        {
            key: 'showing',
            label: (
                <a href='/showing'>
                  Showing
                </a>
              ),
        },
        {
            key: "offer",
            label: (
                <a href='/offer'>
                  Offer
                </a>
              ),
        },
        {
            key: "profile",
            label: (
                <a href='/profile'>
                  Profile
                </a>
            ),
        },
    ];

    if (isAgent) {
        items.push({
            key: "agent-offers",
            label: (
                <a href='/agent-offers'>
                  Manage offers
                </a>
            ),
        });

        items.push({
            key: "agent-showings",
            label: (
                <a href='/agent-showings'>
                  Manage showings
                </a>
            ),
        });
    }

    if (isAuthenticated) {
        items.push({
            key: "logout",
            label: (
                <LogoutButton />
            ),
        });
    } else {
        items.push({
            key: "signup",
            label: (
                <SignupButton />
            ),
        });
        items.push({
            key: "login",
            label: (
                <LoginButton />
            ),
        });
    }

    return (
        <Header style={headerStyle}>
            <Brand />
            <Menu
                mode="horizontal"
                defaultSelectedKeys={[route ?? 'showing']}
                items={items}
                style={{
                    flex: 1,
                    minWidth: 0,
                    justifyContent: 'flex-end',
                    borderBottom: 0,
                }}
            /></Header>
    );
};
