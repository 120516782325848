import { AxiosRequestConfig } from "axios";
import { Showing } from "../models/showing";
import { callExternalApi } from "./external-api.service";
import { ApiResponse } from "src/models/api-response";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const setShowing = async (
    accessToken: string,
    showing: Showing
): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/showings`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        data: showing,
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
        data,
        error,
    };
};

export const getShowingsByUser = async (
    accessToken: string,
    userId: string
): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/showings?user_id=${userId}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
        data,
        error,
    };
};

export const getShowingsByAgent = async (
    accessToken: string,
    agentId: string
): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/showings?agent_id=${agentId}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
        data,
        error,
    };
};