import React, { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home-page";
import { ProfilePage } from "./pages/profile-page";
import { ShowingPage } from "./pages/showing-page";
import { OfferPage } from "./pages/offer-page";
import { AgentOffersPage } from "./pages/agent-offers-page";
import { AgentShowingsPage } from "./pages/agent-showings-page";

import { getAgent } from "src/services/agent.service";

export const App: React.FC = () => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();

  //const [isAgent, setIsAgent] = useState(false);
  //const [isLoadingAgent, setIsLoadingAgent] = useState(true);

//   useEffect(() => {
//     let isMounted = true;

//     const loadAgent = async () => {
//         if (!isMounted || isLoading) {
//             return;
//         }

//         const accessToken = await getAccessTokenSilently();
//         const userId = user == null ? '' : user.usb === null ? '' : user.sub;
//         const { data, error } = await getAgent(accessToken, userId ?? '');

//         if (data) {
//             setIsAgent(true);
//         }

//         if (error) {
//             // TODO: set error state
//         }

//         setIsLoadingAgent(false);
//     };

//     loadAgent();

//     return () => {
//         isMounted = false;
//     };
// }, [isLoading]);

  //if (isLoading || isLoadingAgent) {
  if (isLoading) { 
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  // const RedirectUrl = ({ url }: { url: string }) => {
  //   useEffect(() => {
  //     window.location.href = url;
  //   }, [url]);
  
  //   return <h5>Redirecting...</h5>;
  // };

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/showing"
        element={<AuthenticationGuard component={ShowingPage} />}
      />
      <Route
        path="/callback"
        element={<AuthenticationGuard component={ShowingPage} />}
      />
      <Route
        path="/offer"
        element={<AuthenticationGuard component={OfferPage} />}
      />
      <Route
        path="/agent-offers"
        element={<AuthenticationGuard component={AgentOffersPage} />}
      />
      <Route
        path="/agent-showings"
        element={<AuthenticationGuard component={AgentShowingsPage} />}
      />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
};
