import React from "react";
import { Button, Form, Input, Select, Space } from 'antd';

interface Props {
    firstBuyerName: string;
    firstBuyerEmail: string;
    firstBuyerPhoneNumber: string;
    secondBuyerName: string;
    secondBuyerEmail: string;
    marriageStatus: string;
    handleFirstBuyerNameChange: (e: any) => void
    handleFirstBuyerEmailChange: (e: any) => void
    handleFirstBuyerPhoneNumberChange: (e: any) => void
    handleSecondBuyerNameChange: (e: any) => void
    handleSecondBuyerEmailChange: (e: any) => void
    handleMarriageStatusChange: (e: any) => void
    onNext: () => void
}

export const BuyerInfo: React.FC<Props> = ({
    firstBuyerName,
    firstBuyerEmail,
    firstBuyerPhoneNumber,
    secondBuyerName,
    secondBuyerEmail,
    marriageStatus,
    handleFirstBuyerNameChange,
    handleFirstBuyerEmailChange,
    handleFirstBuyerPhoneNumberChange,
    handleSecondBuyerNameChange,
    handleSecondBuyerEmailChange,
    handleMarriageStatusChange,
    onNext,
}) => {

    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    return (
        <Form
            name="buyer-info"
            onFinish={onNext}
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                'firstBuyerName': firstBuyerName,
                'firstBuyerEmail': firstBuyerEmail,
                'firstBuyerPhoneNumber': firstBuyerPhoneNumber,
                'secondBuyerName': secondBuyerName,
                'secondBuyerEmail': secondBuyerEmail,
                'marriageStatus': marriageStatus,
            }}
            autoComplete="off"
        >
            <Form.Item
                name="firstBuyerName"
                label="First buyer name"
                rules={[
                    {
                        required: true,
                        message: 'First buyer name is required',
                    },
                ]}
            >
                <Input onChange={handleFirstBuyerNameChange} />
            </Form.Item>

            <Form.Item
                name='firstBuyerEmail'
                label="First buyer email"
                rules={[
                    { required: true, message: 'Required' },
                    {
                        type: 'email',
                        message: 'invalid email format',
                    },
                ]}>
                <Input onChange={handleFirstBuyerEmailChange} />
            </Form.Item>

            <Form.Item
                name="firstBuyerPhoneNumber"
                label="First buyer phone number"
            >
                <Input onChange={handleFirstBuyerPhoneNumberChange} />
            </Form.Item>

            <Form.Item
                name="secondBuyerName"
                label="Second buyer name"
            >
                <Input onChange={handleSecondBuyerNameChange} />
            </Form.Item>

            <Form.Item
                name='secondBuyerEmail'
                label="Second buyer email"
                rules={[
                    {
                        type: 'email',
                        message: 'invalid email format',
                    },
                ]}>
                <Input onChange={handleSecondBuyerEmailChange} />
            </Form.Item>

            <Form.Item name="marriageStatus" label="marriage status" rules={[{ required: true, message: 'Required field' }]}>
                <Select
                    onChange={handleMarriageStatusChange}
                    options={[
                        {
                            value: 'aMarriedCouple',
                            label: 'A married couple',
                        },
                        {
                            value: 'anUnmarriedPerson',
                            label: 'An unmarried person',
                        },
                        {
                            value: 'unmarriedPersons',
                            label: 'Unmarried persons',
                        },
                        {
                            value: 'domesticPartners',
                            label: 'Domestic partners',
                        },
                        {
                            value: 'marriedAsSeparateProperty',
                            label: 'Married as separate property',
                        },
                    ]}
                />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Space className='form-button-group'>
                    <Button type="primary" htmlType="submit">
                        Next
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
