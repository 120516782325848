import React, {useState} from "react";
import { Col, InputNumber, Row, Slider, Space, Typography } from 'antd';
import Utils from "../util/utilities";

interface Props {
}

export const FeeCalculator: React.FC<Props> = ({ }) => {

    const feeCapPercentage = 0.015;
    const basePrice = 4000;
    const showingPrice = 100;
    const offerPrice = 200;

    const { Title, Text } = Typography;

    const [showingNumber, setShowingNumber] = useState(6);
    const [offerNumber, setOfferNumber] = useState(3);
    const [purchasePrice, setPurchasePrice] = useState(600000);
    const onShowingNumberChange = (newValue: number | null) => {
        setShowingNumber(newValue ?? 0);
    };
    const onOfferNumberChange = (newValue: number | null) => {
        setOfferNumber(newValue ?? 0);
    };
    const onPurchasePriceChange = (newValue: number | null) => {
        setPurchasePrice(newValue ?? 0);
    };

    const spacerStyle = {
        marginTop: '15px',
    };
    const feeLabelStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
    };

    const percentageFormatter = (percent: number) => `${(percent * 100).toFixed(1)}%`;

    const showingLimit = 50;
    const showingNumberSlider = (
        <div style={spacerStyle}>
            <div style={feeLabelStyle}>
                <Text>Our showing price per house is:</Text>
                <Text>{Utils.dollarFormatter.format(showingPrice)}</Text>
            </div>
            <div style={feeLabelStyle}>
                <Text>Number of showings:</Text>
                <InputNumber
                    min={1}
                    max={showingLimit}
                    value={showingNumber}
                    onChange={onShowingNumberChange}
                />
            </div>
            <Slider
                min={1}
                max={showingLimit}
                onChange={onShowingNumberChange}
                value={typeof showingNumber === 'number' ? showingNumber : 0}
            />
        </div>
    );

    const offerLimit = 20;
    const offerNumberSlider = (
        <div style={spacerStyle}>
            <div style={feeLabelStyle}>
                <Text>Our offer price per house is:</Text>
                <Text>{Utils.dollarFormatter.format(offerPrice)}</Text>
            </div>
            <div style={feeLabelStyle}>
                <Text>Number of offers:</Text>
                <InputNumber
                    min={1}
                    max={offerLimit}
                    value={offerNumber}
                    onChange={onOfferNumberChange}
                />
            </div>
            <Slider
                min={1}
                max={offerLimit}
                onChange={onOfferNumberChange}
                value={typeof offerNumber === 'number' ? offerNumber : 0}
            />
        </div>
    );

    const feeCap = (
        <div style={spacerStyle}>
            <div style={feeLabelStyle}>
                <Text>We cap our fee at {percentageFormatter(feeCapPercentage)} of the price for the house you finally purchase.</Text>
            </div>
            <div style={feeLabelStyle}>
                <Text>Your estimated purchase price is:</Text>
                <Text>{Utils.dollarFormatter.format(purchasePrice)}</Text>
            </div>
            <div style={feeLabelStyle}>
                <Text>Please enter your estimated purchase price:</Text>
                <InputNumber
                    value={null}
                    onChange={onPurchasePriceChange}
                />
            </div>
        </div>
    );

    const calculatedFee = 4000+100*showingNumber+200*offerNumber;
    const finalFee = Math.min(calculatedFee, feeCapPercentage*purchasePrice);
    return (
        <Space className="fee-calculator"
            style={{
                width: '100%',
            }}
            direction="vertical"
        >
            <Title level={3}>Estimate your cost</Title>
            <Text style={spacerStyle}>Total commission = base price + number of showings * showing price + number of offer * offer price.</Text>
            <div style={spacerStyle}>
                <div style={feeLabelStyle}>
                    <Text>Our base price is:</Text>
                    <Text>{Utils.dollarFormatter.format(basePrice)}</Text>
                </div>
            </div>
            {showingNumberSlider}
            {offerNumberSlider}
            {feeCap}
            <div style={spacerStyle}>
                <div style={feeLabelStyle}>
                    <Text>Your total estimated fee is:</Text>
                    <Text strong>{Utils.dollarFormatter.format(finalFee)}</Text>
                </div>
            </div>
            <div style={spacerStyle}>
                <Text strong>Note: </Text><Text>on average, our customers need</Text><Text italic> 6 showings </Text><Text>and</Text><Text italic> 3 offers </Text><Text>before closing on a house, which translates to {} cost.</Text>
            </div>
        </Space>
    );
};
