
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Collapse, Layout, Typography } from 'antd';

import { AppLayout } from "../components/app-layout";
import { FeeCalculator } from '../components/fee-calculator';

export const HomePage: React.FC = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
    const { Sider, Content } = Layout;
    const { Title } = Typography;

    const siderStyle = {
        color: '#fff',
        padding: 24,
        backgroundColor: 'white',
        opacity: '0.9',
    };

    const getHomePageMessage = () => {
        return (
            <Title level={2} style={{
                color: 'gray'
            }}>Our AI powered chat bot is coming soon!</Title>
        );
    }

    const desktopPage = (
        <Layout className='layout'>
            <Content style={{
                marginRight: '24px'
            }}>
                <Content style={siderStyle}>
                    {getHomePageMessage()}
                </Content>
            </Content>
            <Sider width="30%" style={siderStyle}>
                <FeeCalculator />
            </Sider>
        </Layout>
    );

    const feeCalculatorStyle = {
        color: '#fff',
        padding: 24,
        backgroundColor: 'white',
        opacity: '0.9',
    };

    const costEstimator = (
        <Content style={feeCalculatorStyle}>
            <FeeCalculator />
        </Content>
    );

    const collapseItems = [
        {
            key: '1',
            label: 'Cost Estimator',
            children: costEstimator,
        },
    ];

    const collapseStyle = {
        backgroundColor: 'white',
        borderRadius: '0 0 0 0',
        opacity: '0.9',
    };

    const mobilePage = (
        <Layout className='layout'>
            <Collapse items={collapseItems} style={collapseStyle} />
            <Layout style={{
                marginTop: '24px'
            }}>
                <Content style={siderStyle}>
                    {getHomePageMessage()}
                </Content>
            </Layout>
        </Layout>
    );

    return (
        <AppLayout route='home'>
            <>
                <div>{isTabletOrMobile ? mobilePage : desktopPage}</div>
            </>
        </AppLayout>
    );
};
