import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Tag, List, Table, Space, Typography, Button, message } from 'antd';

import Utils from '../util/utilities';
import { Showing } from "src/models/showing";
import { setShowing, getShowingsByUser } from "src/services/showing.service";
import Constants from "src/util/constants";

interface Props {
    setShowingState: (offer: Showing) => void;
}

export const ShowingList: React.FC<Props> = ({
    setShowingState,
}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { Title, Paragraph } = Typography;

    const { user, getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [showings, setShowings] = useState<Map<string, Showing>>(new Map());

    useEffect(() => {
        let isMounted = true;

        const loadShowings = async () => {
            const accessToken = await getAccessTokenSilently();
            const userId = user == null ? '' : user.usb === null ? '' : user.sub;
            const { data, error } = await getShowingsByUser(accessToken, userId ?? '');

            if (!isMounted) {
                return;
            }

            setIsLoading(false);

            if (data) {
                const showingMap = new Map();
                data.forEach((e: Showing) => {
                    showingMap.set(e.showingId, e);
                });
                setShowings(showingMap);
            }

            if (error) {
                // TODO: set error state
                setShowings(new Map());
            }
        };

        loadShowings();

        return () => {
            isMounted = false;
        };
    }, []);

    const columns = [
        {
            title: 'Property address or link',
            dataIndex: 'propertyAddressOrLink',
        },
        {
            title: 'Showing date',
            dataIndex: 'showingDate',
        },
        {
            title: 'Showing time',
            dataIndex: 'showingTime',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_: any, record: any) => {
                if (record.showing.status === Constants.ShowingStatusProposedNewTime) {
                    return (
                        <Tag color="processing">New Time Proposed</Tag>
                    )
                } else {
                    return record.showing.status == '' ? 'pending' : record.showing.status;
                }
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (_: any, record: any) => <a onClick={() => setShowingState(record.showing)}>Edit</a>,
        },
    ];

    const showingRows: any = [];
    const expandedRowKeys: any = [];
    if (!isLoading) {
        showings.forEach((item: Showing) => {
            showingRows.push({
                key: item.showingId,
                propertyAddressOrLink: item.propertyAddressOrLink,
                showingDate: item.showingDate,
                showingTime: item.showingTime,
                status: Utils.isEmptyValue(item.status) ? 'pending' : item.status,
                showing: item,
            });

            if (item.status === Constants.ShowingStatusProposedNewTime) {
                expandedRowKeys.push(item.showingId);
            }
        });
    }

    const acceptedProposedShowingDateTime = (showing: Showing) => {
        const saveShowing = async () => {
            showing.showingDate = showing.agentShowingDate;
            showing.showingTime = showing.agentShowingTime;
            showing.agentShowingDate = '';
            showing.agentShowingTime = '';
            showing.status = Constants.ShowingStatusConfirmed;
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await setShowing(accessToken, showing);
            if (data) {
                if (showing.showingId) {
                    const newMap = new Map(showings);
                    newMap.set(showing.showingId, data);
                    setShowings(newMap);
                    messageApi.info('Successfully accepted the showing request.');
                } else {
                    messageApi.info('Failed to accepted the showing request.');
                }

            }

            if (error) {
                messageApi.info('Failed to accepted the showing request.');
            }
        };

        saveShowing();
    };

    const tableStyle = {
        paddingTop: 24,
    };

    const expandableStyle = {
        paddingLeft: 48,
        paddingRight: 48,
    };

    const renderProposedTime = (showing: Showing) => {
        if (showing.status !== Constants.ShowingStatusProposedNewTime) {
            return null;
        }

        const data = [
            {
                title: 'Proposed showing date',
                description: showing.agentShowingDate,
            },
            {
                title: 'Proposed showing time',
                description: showing.agentShowingTime,
            },
            {
                title: 'Agent notes',
                description: showing.agentNote,
            },
        ];

        return (
            <>
                <Title level={5} style={{ ...expandableStyle, paddingTop: '12px' }}>New Time Proposed</Title>
                <Paragraph style={expandableStyle}>
                    The original requested showing time doesn't work.
                    Your agent proposed the following showing time with notes.
                    If the new showing time works for you, please accept it. Then, the showing time is confirmed.
                </Paragraph>
                <Paragraph style={expandableStyle}>
                    If the proposed new showing time doesn't work for you, you can edit the showing request and submit it again.
                </Paragraph>
                <List
                    style={expandableStyle}
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.title}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                />
                <Space style={expandableStyle} className='form-button-group'>
                    <Button
                        type='primary'
                        onClick={() => acceptedProposedShowingDateTime(showing)}>
                        Accept
                    </Button>
                </Space>
            </>);
    }

    return (<Table
        size="small"
        loading={isLoading}
        columns={columns}
        dataSource={showingRows}
        style={tableStyle}
        expandable={{
            defaultExpandedRowKeys: expandedRowKeys,
            expandedRowRender: (record) => {
                const data = [
                    {
                        title: 'Showing Duration',
                        description: record.showing.showingDuration,
                    },
                    {
                        title: 'Note',
                        description: record.showing.note,
                    },
                ];

                return (
                    <>
                        <List
                            style={expandableStyle}
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={item.title}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />

                        {renderProposedTime(record.showing)}
                    </>
                );
            },
        }}
    />);

};
