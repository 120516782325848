import React from "react";
import { Button, Form, Input, Space } from 'antd';

interface Props {
    propertyAddress: string;
    thirdPartyLink: string;
    handlePropertyAddressChange: (e: any) => void;
    handleThirdPartyLinkChange: (e: any) => void;
    onNext: () => void;
    onBack: () => void;
}

export const PropertyInfo: React.FC<Props> = ({
    propertyAddress,
    thirdPartyLink,
    handlePropertyAddressChange,
    handleThirdPartyLinkChange,
    onNext,
    onBack,
}) => {

    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    return (
        <Form
            name="property-info"
            onFinish={onNext}
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                'propertyAddress': propertyAddress,
                'thirdPartyLink': thirdPartyLink,
            }}
            autoComplete="off"
        >
            <Form.Item
                name="propertyAddress"
                label="Property address"
                rules={[
                    {
                        required: true,
                        message: 'Property address is required',
                    },
                ]}
            >
                <Input onChange={handlePropertyAddressChange} />
            </Form.Item>

            <Form.Item
                name='thirdPartyLink'
                label="Redfin or Zillow link"
            >
                <Input onChange={handleThirdPartyLinkChange} />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Space className='form-button-group'>
                    <Button type="primary" htmlType="submit">
                        Next
                    </Button>
                    <Button htmlType="button" onClick={onBack}>
                        Back
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
