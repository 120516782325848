export default class Constants {
    static MAX_FILE_NAME_LENGTH = 100;
    static MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
    static ALLOWED_FILE_MIME_TYPES = {
        '.pdf': 'application/pdf',
        '.doc': 'application/msword',
        '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.png': 'image/png',
        '.jpeg': 'image/jpeg',
    }

    static DATE_FORMAT = 'MM/DD/YYYY';
    static TIME_FORMAT = 'hh:mm a';

    static getAllowedFileTypeNames = () => {
        return Object.keys(Constants.ALLOWED_FILE_MIME_TYPES);
    }

    static getAllowedFileTypes = () => {
        return Object.values(Constants.ALLOWED_FILE_MIME_TYPES);
    }

    static ShowingStatusConfirmed = 'Confirmed';
    static ShowingStatusProposedNewTime = 'ProposedNewTime';

    static ShowingDuration15Min = '15 Minutes';
    static ShowingDuration30Min = '30 Minutes';
    static ShowingDuration45Min = '45 Minutes';
    static ShowingDuration60Min = '60 Minutes';
}