import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "react-responsive";
import { Steps, Layout, Typography } from 'antd';

import { AppLayout } from "../components/app-layout";
import { CodeSnippet } from "../components/code-snippet";
import { BuyerInfo } from "src/components/offer-forms/buyer-info";
import { PropertyInfo } from "src/components/offer-forms/property-info";
import { ContractInfo } from "src/components/offer-forms/contract-info";
import { LoanInfo } from "src/components/offer-forms/loan-info";
import { Offer } from "src/models/offer";
import { setOfferResource } from "src/services/offer.service";
import { OfferList } from "src/components/offer-list";

export const OfferPage: React.FC = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [message, setMessage] = useState<string>("");

    const [current, setCurrent] = useState(0);
    const [offerComponentKey, setOfferComponentKey] = useState('');
    const [offerListComponentKey, setOfferListComponentKey] = useState('');

    const [offerId, setOfferId] = useState('');
    const [firstBuyerName, setFirstBuyerName] = useState('');
    const [firstBuyerEmail, setFirstBuyerEmail] = useState('');
    const [firstBuyerPhoneNumber, setFirstBuyerPhoneNumber] = useState('');
    const [secondBuyerName, setSecondBuyerName] = useState('');
    const [secondBuyerEmail, setSecondBuyerEmail] = useState('');
    const [marriageStatus, setMarriageStatus] = useState('');
    const [propertyAddress, setPropertyAddress] = useState('');
    const [thirdPartyLink, setThirdPartyLink] = useState('');
    const [offerDate, setOfferDate] = useState('');
    const [offerExpirationDate, setOfferExpirationDate] = useState('');
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [earnestMoneyAmount, setEarnestMoneyAmount] = useState(0);
    const [earnestMoneyDeliveryDays, setEarnestMoneyDeliveryDays] = useState('');
    const [loanType, setLoanType] = useState('');
    const [closingDate, setClosingDate] = useState('');
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(0);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

    if (!user) {
        return null;
    }

    const { Content } = Layout;
    const { Title } = Typography;

    function handleFirstBuyerNameChange(e: any) {
        setFirstBuyerName(e.target.value);
    }

    function handleFirstBuyerEmailChange(e: any) {
        setFirstBuyerEmail(e.target.value);
    }

    function handleFirstBuyerPhoneNumberChange(e: any) {
        setFirstBuyerPhoneNumber(e.target.value);
    }

    function handleSecondBuyerNameChange(e: any) {
        setSecondBuyerName(e.target.value);
    }

    function handleSecondBuyerEmailChange(e: any) {
        setSecondBuyerEmail(e.target.value);
    }

    function handleMarriageStatusChange(value: string) {
        setMarriageStatus(value);
    }

    function handlePropertyAddressChange(e: any) {
        setPropertyAddress(e.target.value);
    }

    function handleThirdPartyLinkChange(e: any) {
        setThirdPartyLink(e.target.value);
    }

    function handleOfferDateChange(value: string) {
        setOfferDate(value);
    }

    function handleOfferExpirationDateChange(value: string) {
        setOfferExpirationDate(value);
    }

    function handlePurchasePriceChange(value: number) {
        setPurchasePrice(value);
    }

    function handleEarnestMoneyAmountChange(value: number) {
        setEarnestMoneyAmount(value);
    }

    function handleEarnestMoneyDeliveryDaysChange(value: string) {
        setEarnestMoneyDeliveryDays(value);
    }

    function handleLoanTypeChange(value: string) {
        setLoanType(value);
    }

    function handleClosingDateChange(value: string) {
        setClosingDate(value);
    }

    function handleDownPaymentPercentageChange(value: number) {
        setDownPaymentPercentage(value);
    }

    const setOffer = (offer: Offer) => {
        setOfferId(offer.offerId ?? '');
        setFirstBuyerName(offer.firstBuyerName);
        setFirstBuyerEmail(offer.firstBuyerEmail);
        setFirstBuyerPhoneNumber(offer.firstBuyerPhoneNumber);
        setSecondBuyerName(offer.secondBuyerName);
        setSecondBuyerEmail(offer.secondBuyerEmail);
        setMarriageStatus(offer.marriageStatus);
        setPropertyAddress(offer.propertyAddress);
        setThirdPartyLink(offer.thirdPartyLink);
        setOfferDate(offer.offerDate);
        setOfferExpirationDate(offer.offerExpirationDate);
        setPurchasePrice(offer.purchasePrice);
        setEarnestMoneyAmount(offer.earnestMoneyAmount);
        setEarnestMoneyDeliveryDays(offer.earnestMoneyDeliveryDays);
        setLoanType(offer.loanType);
        setClosingDate(offer.closingDate);
        setDownPaymentPercentage(offer.downPaymentPercentage);

        // Set offerComponentKey will trigger offer forms to re-render and load the new offer state.
        setOfferComponentKey(offer.offerId ?? '');
        setCurrent(0);
    };

    const onNext = () => {
        setCurrent(current + 1);
    };

    const onBack = () => {
        setCurrent(current - 1);
    };

    const onComplete = () => {
        const saveOffer = async () => {
            const offer: Offer = {
                userId: user.sub ?? '',
                offerId: offerId,
                firstBuyerName: firstBuyerName,
                firstBuyerEmail: firstBuyerEmail,
                firstBuyerPhoneNumber: firstBuyerPhoneNumber,
                secondBuyerName: secondBuyerName,
                secondBuyerEmail: secondBuyerEmail,
                marriageStatus: marriageStatus,
                propertyAddress: propertyAddress,
                thirdPartyLink: thirdPartyLink,
                offerDate: offerDate,
                offerExpirationDate: offerExpirationDate,
                purchasePrice: purchasePrice,
                earnestMoneyAmount: earnestMoneyAmount,
                earnestMoneyDeliveryDays: earnestMoneyDeliveryDays,
                loanType: loanType,
                closingDate: closingDate,
                downPaymentPercentage: downPaymentPercentage,
            };

            const accessToken = await getAccessTokenSilently();
            const { data, error } = await setOfferResource(accessToken, offer);
            if (data) {
                setMessage(JSON.stringify(data, null, 2));
            }

            if (error) {
                setMessage(JSON.stringify(error, null, 2));
            }
        };

        saveOffer();
        setOfferComponentKey('');
        // set a new key to trigger a re-render of the offer list component to load the updates or new offer.
        setOfferListComponentKey(Math.random().toString());
        setCurrent(current);
    };


    const contentStyle = {
        color: '#fff',
        backgroundColor: 'transparent',
    };

    const steps = [
        {
            title: 'Buyer Info',
            content: (
                <BuyerInfo
                    key={offerComponentKey}
                    firstBuyerName={firstBuyerName}
                    firstBuyerEmail={firstBuyerEmail}
                    firstBuyerPhoneNumber={firstBuyerPhoneNumber}
                    secondBuyerName={secondBuyerName}
                    secondBuyerEmail={secondBuyerEmail}
                    marriageStatus={marriageStatus}
                    handleFirstBuyerNameChange={handleFirstBuyerNameChange}
                    handleFirstBuyerEmailChange={handleFirstBuyerEmailChange}
                    handleFirstBuyerPhoneNumberChange={handleFirstBuyerPhoneNumberChange}
                    handleSecondBuyerNameChange={handleSecondBuyerNameChange}
                    handleSecondBuyerEmailChange={handleSecondBuyerEmailChange}
                    handleMarriageStatusChange={handleMarriageStatusChange}
                    onNext={onNext}
                />
            ),
        },
        {
            title: 'Property Info',
            content: (
                <PropertyInfo
                    propertyAddress={propertyAddress}
                    thirdPartyLink={thirdPartyLink}
                    handlePropertyAddressChange={handlePropertyAddressChange}
                    handleThirdPartyLinkChange={handleThirdPartyLinkChange}
                    onNext={onNext}
                    onBack={onBack}
                />
            ),
        },
        {
            title: 'Contract Details',
            content: (
                <ContractInfo
                    offerDate={offerDate}
                    offerExpirationDate={offerExpirationDate}
                    purchasePrice={purchasePrice}
                    earnestMoneyAmount={earnestMoneyAmount}
                    earnestMoneyDeliveryDays={earnestMoneyDeliveryDays}
                    handleOfferDateChange={handleOfferDateChange}
                    handleOfferExpirationDateChange={handleOfferExpirationDateChange}
                    handlePurchasePriceChange={handlePurchasePriceChange}
                    handleEarnestMoneyAmountChange={handleEarnestMoneyAmountChange}
                    handleEarnestMoneyDeliveryDaysChange={handleEarnestMoneyDeliveryDaysChange}
                    onNext={onNext}
                    onBack={onBack}
                />
            ),
        },
        {
            title: 'Loan Info',
            content: (
                <LoanInfo
                    loanType={loanType}
                    closingDate={closingDate}
                    downPaymentPercentage={downPaymentPercentage}
                    handleLoanTypeChange={handleLoanTypeChange}
                    handleClosingDateChange={handleClosingDateChange}
                    handleDownPaymentPercentageChange={handleDownPaymentPercentageChange}
                    onComplete={onComplete}
                    onBack={onBack}
                />
            ),
        },
        // {
        //     title: "Save Offer",
        //     content: (
        //         <div>
        //             <Title level={2}>Offer Saved</Title>
        //             <CodeSnippet title="Saved Offer" code={message} />
        //         </div>
        //     ),
        // },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const offerFormStyle = {
        color: '#fff',
        padding: 24,
        backgroundColor: 'white',
        opacity: '0.9',
    };

    const fieldGroupStyle = {
        paddingTop: 40,
        maxWidth: 1000,
    };

    const desktopPage = (
        <Layout className='layout'>
            <Content style={offerFormStyle}>
                <Steps current={current} items={items} />
                <div style={fieldGroupStyle}>
                    {steps[current].content}
                </div>
            </Content>
        </Layout>
    );

    const mobilePage = (
        <Layout className='layout'>
            <Content style={offerFormStyle}>
                <Steps current={current} items={items} />
                <div style={fieldGroupStyle}>
                    {steps[current].content}
                </div>
            </Content>
        </Layout>
    );

    /*********** debugging code *************/
    const columns = [
        {
            title: 'Feild for debugging',
            dataIndex: 'Field',
            key: 'Field',
        },
        {
            title: 'Value',
            dataIndex: 'Value',
            key: 'Value',
        },
    ];

    const data = [
        {
            Field: 'firstBuyerName',
            Value: firstBuyerName,
        },
        {
            Field: 'firstBuyerEmail',
            Value: firstBuyerEmail,
        },
        {
            Field: 'firstBuyerPhoneNumber',
            Value: firstBuyerPhoneNumber,
        },
        {
            Field: 'secondBuyerName',
            Value: secondBuyerName,
        },
        {
            Field: 'secondBuyerEmail',
            Value: secondBuyerEmail,
        },
        {
            Field: 'marriageStatus',
            Value: marriageStatus,
        },
        {
            Field: 'propertyAddress',
            Value: propertyAddress,
        },
        {
            Field: 'thirdPartyLink',
            Value: thirdPartyLink,
        },
        {
            Field: 'offerDate',
            Value: offerDate,
        },
        {
            Field: 'offerExpirationDate',
            Value: offerExpirationDate,
        },
        {
            Field: 'purchasePrice',
            Value: purchasePrice,
        },
        {
            Field: 'earnestMoneyAmount',
            Value: earnestMoneyAmount,
        },
        {
            Field: 'earnestMoneyDeliveryDays',
            Value: earnestMoneyDeliveryDays,
        },
    ];

    const tableStyle = {
        width: 800,
        padding: 24,
    };

    return (
        <AppLayout route="offer">
            <>
                <div>{isTabletOrMobile ? mobilePage : desktopPage}</div>
                <OfferList key={offerListComponentKey} setOffer={setOffer}/>
                {/* <Table size="small" columns={columns} dataSource={data} style={tableStyle} /> */}
            </>
        </AppLayout>
    );
};
