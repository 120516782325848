import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { useAuth0 } from "@auth0/auth0-react";
import { Button, Descriptions, Form, DatePicker, TimePicker, Input, Select, Space, Table, message, Typography } from 'antd';
import type { DescriptionsProps } from 'antd';
import dayjs from 'dayjs';

import { AppLayout } from "../components/app-layout";

import { setShowing, getShowingsByAgent } from "src/services/showing.service";
import { Showing } from "src/models/showing";
import Utils from '../util/utilities';
import Constants from 'src/util/constants';

export const AgentShowingsPage: React.FC = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [messageApi, contextHolder] = message.useMessage();
    const { TextArea } = Input;
    const { Title } = Typography;
    const [isLoading, setIsLoading] = useState(true);
    const [showings, setShowings] = useState<Map<string, Showing>>(new Map());
    const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

    useEffect(() => {
        let isMounted = true;

        const loadShowings = async () => {
            const accessToken = await getAccessTokenSilently();
            const userId = user == null ? '' : user.usb === null ? '' : user.sub;
            const { data, error } = await getShowingsByAgent(accessToken, userId ?? '');

            if (!isMounted) {
                return;
            }

            setIsLoading(false);

            if (data) {
                const showingMap = new Map();
                data.forEach((e: Showing) => {
                    showingMap.set(e.showingId, e);
                });
                setShowings(showingMap);
            }

            if (error) {
                // TODO: set error state
                setShowings(new Map());
            }
        };

        loadShowings();

        return () => {
            isMounted = false;
        };
    }, []);

    if (!user) {
        return null;
    }

    const columns = [
        {
            title: 'Buyer Name',
            dataIndex: 'buyerName',
        },
        {
            title: 'Property address or link',
            dataIndex: 'propertyAddressOrLink',
        },
        {
            title: 'Showing date',
            dataIndex: 'showingDate',
        },
        {
            title: 'Showing time',
            dataIndex: 'showingTime',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
    ];

    const showingRows: any = [];
    if (!isLoading) {
        showings.forEach((value, key) => {
            showingRows.push({
                key: key,
                buyerName: value.userName,
                propertyAddressOrLink: value.propertyAddressOrLink,
                showingDate: value.showingDate,
                showingTime: value.showingTime,
                status: Utils.isEmptyValue(value.status) ? 'pending' : value.status,
                fullShowing: value,
            });
        });
    }

    const tableStyle = {
        padding: 24,
    };

    const updateShowing = (showingId: string, status: string) => {
        const saveShowing = async () => {
            const showing = showings.get(showingId);
            if (showing != null) {
                showing.status = status;
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await setShowing(accessToken, showing);
                if (data) {
                    const newMap = new Map(showings);
                    newMap.set(showingId, showing);
                    setShowings(newMap);
                    messageApi.info('Successfully updated the showing request.');
                }

                if (error) {
                    messageApi.info('Failed to update the showing request.');
                }
            } else {
                messageApi.info('Failed to confirm showing.');
            }
        };

        saveShowing();
    };

    const handleShowingDateChange = (showingId: string, showingDate: string) => {
        const newMap = new Map(showings);
        const showing = newMap.get(showingId);
        if (showing) {
            showing.showingDate = showingDate;
            newMap.set(showingId, showing);
            setShowings(newMap);
        } else {
            messageApi.info('Failed to set showing date.');
        }
    }

    const handleShowingTimeChange = (showingId: string, showingTime: string) => {
        const newMap = new Map(showings);
        const showing = newMap.get(showingId);
        if (showing) {
            showing.showingTime = showingTime;
            newMap.set(showingId, showing);
            setShowings(newMap);
        } else {
            messageApi.info('Failed to set showing time.');
        }
    }

    const handleShowingDurationChange = (showingId: string, showingDuration: string) => {
        const newMap = new Map(showings);
        const showing = newMap.get(showingId);
        if (showing) {
            showing.showingDuration = showingDuration;
            newMap.set(showingId, showing);
            setShowings(newMap);
        } else {
            messageApi.info('Failed to set showing duration.');
        }
    }

    const handleAgentShowingDateChange = (showingId: string, agentShowingDate: string) => {
        const newMap = new Map(showings);
        const showing = newMap.get(showingId);
        if (showing) {
            showing.agentShowingDate = agentShowingDate;
            newMap.set(showingId, showing);
            setShowings(newMap);
        } else {
            messageApi.info('Failed to set new showing date.');
        }
    }

    const handleAgentShowingTimeChange = (showingId: string, agentShowingTime: string) => {
        const newMap = new Map(showings);
        const showing = newMap.get(showingId);
        if (showing) {
            showing.agentShowingTime = agentShowingTime;
            newMap.set(showingId, showing);
            setShowings(newMap);
        } else {
            messageApi.info('Failed to set new showing time.');
        }
    }

    const handleAgentNoteChange = (showingId: string, agentNote: string) => {
        const newMap = new Map(showings);
        const showing = newMap.get(showingId);
        if (showing) {
            showing.agentNote = agentNote;
            newMap.set(showingId, showing);
            setShowings(newMap);
        } else {
            messageApi.info('Failed to set showing date.');
        }
    }

    return (
        <AppLayout route="agent-showings">
            <>
                {contextHolder}
                <Table
                    size="small"
                    loading={isLoading}
                    columns={columns}
                    dataSource={showingRows}
                    style={tableStyle}
                    expandable={{
                        expandedRowRender: (record) => {
                            const items: DescriptionsProps['items'] = [
                                {
                                    key: 1,
                                    label: 'Buyer email',
                                    children: record.fullShowing.userEmail,
                                },
                                {
                                    key: 2,
                                    label: 'Note',
                                    children: record.fullShowing.note,
                                },
                            ];

                            return (
                                <Space direction='vertical' style={{
                                    width: '90%',
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}>
                                    <Title level={4}>
                                        Showing Details
                                    </Title>
                                    <Descriptions 
                                        column={isTabletOrMobile ? 1 : 2} 
                                        items={items} />
                                    <Title level={5}>
                                        If the showing time or alternatives requested by buyer works, please confirm the showing time.
                                    </Title>
                                    <Form
                                        key={record.fullShowing.showingId}
                                        name="confirm-showing"
                                        onFinish={() => updateShowing(record.fullShowing.showingId, Constants.ShowingStatusConfirmed)}
                                        layout="vertical"
                                        initialValues={{
                                            'showingDate': !Utils.isEmptyValue(record.fullShowing.showingDate) ? dayjs(record.fullShowing.showingDate, Constants.DATE_FORMAT) : '',
                                            'showingTime': !Utils.isEmptyValue(record.fullShowing.showingTime) ? dayjs(record.fullShowing.showingTime, Constants.TIME_FORMAT) : '',
                                            'showingDuration': !Utils.isEmptyValue(record.fullShowing.showingDuration) ? record.fullShowing.showingDuration : '',
                                        }}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            name='showingDate'
                                            label="Showing date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >

                                            <DatePicker
                                                format={Constants.DATE_FORMAT}
                                                onChange={(e: any) => {
                                                    handleShowingDateChange(record.fullShowing.showingId, dayjs(e).format(Constants.DATE_FORMAT));
                                                }}
                                                disabledDate={(current) => {
                                                    let customDate = dayjs().format(Constants.DATE_FORMAT);
                                                    return current && current < dayjs(customDate, Constants.DATE_FORMAT);
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name='showingTime'
                                            label="Showing time"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <TimePicker
                                                onChange={(e: any) => {
                                                    handleShowingTimeChange(record.fullShowing.showingId, dayjs(e).format(Constants.TIME_FORMAT));
                                                }}
                                                minuteStep={15}
                                                hourStep={1}
                                                use12Hours={true}
                                                format={Constants.TIME_FORMAT} />
                                        </Form.Item>

                                        <Form.Item
                                            name='showingDuration'
                                            label="Showing Duration"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{
                                                    width: '123px',
                                                }}
                                                onChange={(value) => handleShowingDurationChange(record.fullShowing.showingId, value)}
                                                options={[
                                                    {
                                                        value: Constants.ShowingDuration15Min,
                                                        label: Constants.ShowingDuration15Min,
                                                    },
                                                    {
                                                        value: Constants.ShowingDuration30Min,
                                                        label: Constants.ShowingDuration30Min,
                                                    },
                                                    {
                                                        value: Constants.ShowingDuration45Min,
                                                        label: Constants.ShowingDuration45Min,
                                                    },
                                                    {
                                                        value: Constants.ShowingDuration60Min,
                                                        label: Constants.ShowingDuration60Min,
                                                    },
                                                ]}
                                            />
                                        </Form.Item>

                                        <Form.Item>
                                            <Space className='form-button-group'>
                                                <Button type="primary" htmlType="submit">
                                                    Confirm
                                                </Button>
                                            </Space>
                                        </Form.Item>
                                    </Form>

                                    <Space direction='vertical' style={{
                                        width: '100%',
                                    }}>
                                        <Title level={5}>
                                            If the showing time and alternatives requested by buyer doesn't work, please propose a new time and leave some notes.
                                        </Title>
                                        <Form
                                            key={record.fullShowing.showingId}
                                            name="propose-new-time"
                                            onFinish={() => updateShowing(record.fullShowing.showingId, Constants.ShowingStatusProposedNewTime)}
                                            layout="vertical"
                                            initialValues={{
                                                'agentShowingDate': !Utils.isEmptyValue(record.fullShowing.agentShowingDate) ? dayjs(record.fullShowing.agentShowingDate, Constants.DATE_FORMAT) : '',
                                                'agentShowingTime': !Utils.isEmptyValue(record.fullShowing.agentShowingTime) ? dayjs(record.fullShowing.agentShowingTime, Constants.TIME_FORMAT) : '',
                                                'agentNote': record.fullShowing.agentNote,
                                            }}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                name='agentShowingDate'
                                                label="New showing date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >

                                                <DatePicker
                                                    format={Constants.DATE_FORMAT}
                                                    onChange={(e: any) => {
                                                        handleAgentShowingDateChange(record.fullShowing.showingId, dayjs(e).format(Constants.DATE_FORMAT));
                                                    }}
                                                    disabledDate={(current) => {
                                                        let customDate = dayjs().format(Constants.DATE_FORMAT);
                                                        return current && current < dayjs(customDate, Constants.DATE_FORMAT);
                                                    }}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name='agentShowingTime'
                                                label="New showing time"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <TimePicker
                                                    onChange={(e: any) => {
                                                        handleAgentShowingTimeChange(record.fullShowing.showingId, dayjs(e).format(Constants.TIME_FORMAT));
                                                    }}
                                                    minuteStep={15}
                                                    hourStep={1}
                                                    use12Hours={true}
                                                    format={Constants.TIME_FORMAT} />
                                            </Form.Item>

                                            <Form.Item
                                                name='agentNote'
                                                label="Note"
                                            >
                                                <TextArea
                                                    showCount
                                                    maxLength={300}
                                                    onChange={(e: any) => handleAgentNoteChange(record.fullShowing.showingId, e.target.value)}
                                                    placeholder="Please leave some notes for buyer about the new proposed time."
                                                    style={{ height: 120, resize: 'none' }}
                                                />
                                            </Form.Item>

                                            <Form.Item>
                                                <Space className='form-button-group'>
                                                    <Button htmlType="submit">
                                                        Propose new time
                                                    </Button>
                                                </Space>
                                            </Form.Item>
                                        </Form>
                                    </Space>
                                </Space>
                            );
                        },
                    }}
                />
            </>
        </AppLayout>
    );
};
