import React, { useState } from "react";
import { Button, Form, Input, Select, Space, message } from 'antd';
import dayjs from 'dayjs';

import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "react-responsive";
import { Collapse, Layout, DatePicker, TimePicker, Typography } from 'antd';

import { AppLayout } from "../components/app-layout";
import { FeeCalculator } from '../components/fee-calculator';
import { Showing } from "src/models/showing";
import { ShowingList } from "src/components/showing-list";

import { setShowing } from "src/services/showing.service";

import Constants from "src/util/constants";

export const ShowingPage: React.FC = () => {
    const { Title } = Typography;
    const { TextArea } = Input;
    const [messageApi, contextHolder] = message.useMessage();

    const { user, getAccessTokenSilently } = useAuth0();

    const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
    const [showingComponentKey, setShowingComponentKey] = useState('');
    const [showingListComponentKey, setShowingListComponentKey] = useState('');

    const [showingId, setShowingId] = useState('');
    const [propertyAddressOrLink, setPropertyAddressOrLink] = useState('');
    const [showingDate, setShowingDate] = useState('');
    const [showingTime, setShowingTime] = useState('');
    const [showingDuration, setShowingDuration] = useState('');
    const [note, setNote] = useState('');
    const [agentShowingDate, setAgentShowingDate] = useState('');
    const [agentShowingTime, setAgentShowingTime] = useState('');
    const [agentNote, setAgentNote] = useState('');

    if (!user) {
        return null;
    }

    function handlePropertyAddressOrLinkChange(e: any) {
        setPropertyAddressOrLink(e.target.value);
    }

    function handleShowingDateChange(value: string) {
        setShowingDate(value);
    }

    function handleShowingTimeChange(value: any) {
        setShowingTime(value);
    }

    function handleShowingDurationChange(value: string) {
        setShowingDuration(value);
    }

    function handleNoteChange(e: any) {
        setNote(e.target.value);
    }

    const setShowingState = (showing: Showing) => {
        setShowingId(showing.showingId ?? '');
        setPropertyAddressOrLink(showing.propertyAddressOrLink);
        setShowingDate(showing.showingDate);
        setShowingTime(showing.showingTime);
        setShowingDuration(showing.showingDuration);
        setNote(showing.note);
        setAgentShowingDate(showing.agentShowingDate);
        setAgentShowingTime(showing.agentShowingTime);
        setAgentNote(showing.agentNote);

        // Set showingComponentKey will trigger showing forms to re-render and load the new showing state.
        setShowingComponentKey(showing.showingId ?? '');
    };

    const { Sider, Content } = Layout;

    const siderStyle = {
        color: '#fff',
        padding: 24,
        backgroundColor: 'white',
        opacity: '0.9',
    };

    const onComplete = () => {
        const saveShowing = async () => {
            const showing: Showing = {
                userId: user.sub ?? '',
                userEmail: user.email ?? '',
                userName: user.name ?? '',
                showingId: showingId,
                propertyAddressOrLink: propertyAddressOrLink,
                showingDate: showingDate,
                showingTime: showingTime,
                showingDuration: showingDuration,
                note: note,
                agentShowingDate: agentShowingDate,
                agentShowingTime: agentShowingTime,
                agentNote: agentNote,
            };

            const accessToken = await getAccessTokenSilently();
            const { data, error } = await setShowing(accessToken, showing);
            if (data) {
                messageApi.info('Successfully created the showing request.');
            }

            if (error) {
                messageApi.info('Failed to create the showing request.');
            }
        };

        saveShowing();
        setShowingComponentKey('');
        // set a new key to trigger a re-render of the showing list component to load the updates or new showing.
        setShowingListComponentKey(Math.random().toString());
    };

    const getCreateShowingView = () => {
        return (
            <>
                <Title level={3}>
                    Create a showing request
                </Title>
                <Form
                    key={showingComponentKey}
                    name="property-info"
                    onFinish={onComplete}
                    layout="vertical"
                    initialValues={{
                        'propertyAddressOrLink': propertyAddressOrLink,
                        'showingDate': showingDate != '' ? dayjs(showingDate, Constants.DATE_FORMAT) : '',
                        'showingTime': showingTime != '' ? dayjs(showingTime, Constants.TIME_FORMAT) : '',
                        'showingDuration': showingDuration != '' ? showingDuration : '',
                        'note': note,
                    }}
                    autoComplete="off"
                >
                    <Form.Item
                        name="propertyAddressOrLink"
                        label="Property address or third party link (e.g. Redfin or Zillow)"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        <Input onChange={handlePropertyAddressOrLinkChange} />
                    </Form.Item>

                    <Form.Item
                        name='showingDate'
                        label="Showing date"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >

                        <DatePicker
                            format={Constants.DATE_FORMAT}
                            onChange={(e: any) => {
                                handleShowingDateChange(dayjs(e).format(Constants.DATE_FORMAT));
                            }}
                            disabledDate={(current) => {
                                let customDate = dayjs().format(Constants.DATE_FORMAT);
                                return current && current < dayjs(customDate, Constants.DATE_FORMAT);
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name='showingTime'
                        label="Showing time"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        <TimePicker
                            onChange={(e: any) => {
                                handleShowingTimeChange(dayjs(e).format(Constants.TIME_FORMAT));
                            }}
                            minuteStep={15}
                            hourStep={1}
                            use12Hours={true}
                            format={Constants.TIME_FORMAT} />
                    </Form.Item>

                    <Form.Item
                        name='showingDuration'
                        label="Showing Duration"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: '123px',
                            }}
                            onChange={handleShowingDurationChange}
                            options={[
                                {
                                    value: Constants.ShowingDuration15Min,
                                    label: Constants.ShowingDuration15Min,
                                },
                                {
                                    value: Constants.ShowingDuration30Min,
                                    label: Constants.ShowingDuration30Min,
                                },
                                {
                                    value: Constants.ShowingDuration45Min,
                                    label: Constants.ShowingDuration45Min,
                                },
                                {
                                    value: Constants.ShowingDuration60Min,
                                    label: Constants.ShowingDuration60Min,
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        name='note'
                        label="Note"
                    >
                        <TextArea
                            showCount
                            maxLength={300}
                            onChange={handleNoteChange}
                            placeholder="Requested time may not work. Please leave some alternative times."
                            style={{ height: 120, resize: 'none' }}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space className='form-button-group'>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </>
        );
    }

    const showingFormStyle = {
        color: '#fff',
        padding: 24,
        backgroundColor: 'white',
        opacity: '0.9',
    };

    const desktopPage = (
        <Layout className='layout'>
            <Content style={{
                marginRight: '24px'
            }}>
                <Content style={showingFormStyle}>
                    {getCreateShowingView()}
                </Content>
                <ShowingList key={showingListComponentKey} setShowingState={setShowingState} />
            </Content>
            <Sider width="30%" style={siderStyle}>
                <FeeCalculator />
            </Sider>
        </Layout>
    );

    const feeCalculatorStyle = {
        color: '#fff',
        padding: 24,
        backgroundColor: 'white',
        opacity: '0.9',
    };

    const costEstimator = (
        <Content style={feeCalculatorStyle}>
            <FeeCalculator />
        </Content>
    );

    const collapseItems = [
        {
            key: '1',
            label: 'Cost Estimator',
            children: costEstimator,
        },
    ];

    const collapseStyle = {
        backgroundColor: 'white',
        borderRadius: '0 0 0 0',
        opacity: '0.9',
    };

    const mobilePage = (
        <Layout className='layout'>
            <Collapse items={collapseItems} style={collapseStyle} />
            <Layout style={{
                marginTop: '24px'
            }}>
                <Content style={showingFormStyle}>
                    {getCreateShowingView()}
                </Content>
                <ShowingList key={showingListComponentKey} setShowingState={setShowingState} />
            </Layout>
        </Layout>
    );

    return (
        <AppLayout>
            <>
                {contextHolder}
                <div>{isTabletOrMobile ? mobilePage : desktopPage}</div>
            </>
        </AppLayout>
    );
};
