import React from "react";
import { Button, DatePicker, Form, InputNumber, Select, Space } from 'antd';
import dayjs from 'dayjs';
import Constants from "src/util/constants";

interface Props {
    offerDate: string;
    offerExpirationDate: string;
    purchasePrice: number;
    earnestMoneyAmount: number;
    earnestMoneyDeliveryDays: string;
    handleOfferDateChange: (e: any) => void;
    handleOfferExpirationDateChange: (e: any) => void;
    handlePurchasePriceChange: (e: any) => void;
    handleEarnestMoneyAmountChange: (e: any) => void;
    handleEarnestMoneyDeliveryDaysChange: (e: any) => void;
    onNext: () => void;
    onBack: () => void;
}

export const ContractInfo: React.FC<Props> = ({
    offerDate,
    offerExpirationDate,
    purchasePrice,
    earnestMoneyAmount,
    earnestMoneyDeliveryDays,
    handleOfferDateChange,
    handleOfferExpirationDateChange,
    handlePurchasePriceChange,
    handleEarnestMoneyAmountChange,
    handleEarnestMoneyDeliveryDaysChange,
    onNext,
    onBack,
}) => {

    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    let initialOfferDate;
    if (offerDate) {
        initialOfferDate = dayjs(offerDate);
    } else {
        initialOfferDate = dayjs();
        handleOfferDateChange(initialOfferDate.format(Constants.DATE_FORMAT));
    }

    let initialOfferExpirationDate;
    if (offerExpirationDate) {
        initialOfferExpirationDate = dayjs(offerExpirationDate);
    } else {
        initialOfferExpirationDate = dayjs().add(2, 'day');
        handleOfferExpirationDateChange(initialOfferExpirationDate.format(Constants.DATE_FORMAT));
    }

    return (
        <Form
            name="contract-info"
            onFinish={onNext}
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                'offerDate': initialOfferDate,
                'offerExpirationDate': initialOfferExpirationDate,
                'purchasePrice': purchasePrice,
                'earnestMoneyAmount': earnestMoneyAmount,
                'earnestMoneyDeliveryDays': earnestMoneyDeliveryDays == '' ? '2 days' : earnestMoneyDeliveryDays,
            }}
            autoComplete="off"
        >
            <Form.Item
                name="offerDate"
                label="Offer date"
                rules={[
                    {
                        required: true,
                        message: 'Offer date is required',
                    },
                ]}
            >
                <DatePicker onChange={(e: any) => {
                    handleOfferDateChange(dayjs(e).format(Constants.DATE_FORMAT));
                }} />
            </Form.Item>

            <Form.Item
                name="offerExpirationDate"
                label="Offer expriation date"
                rules={[
                    {
                        required: true,
                        message: 'Offer expriation date is required',
                    },
                ]}
            >
                <DatePicker onChange={(e: any) => {
                    handleOfferExpirationDateChange(dayjs(e).format(Constants.DATE_FORMAT));
                }} />
            </Form.Item>

            <Form.Item
                name='purchasePrice'
                label="Purchase price"
                rules={[
                    {
                        required: true,
                        message: 'Purchase price is required',
                    },
                ]}
            >
                <InputNumber
                    style={{
                        width: '100%',
                    }}
                    onChange={handlePurchasePriceChange} />
            </Form.Item>

            <Form.Item
                name='earnestMoneyAmount'
                label="Earnest money amount"
                rules={[
                    {
                        required: true,
                        message: 'Earnest money amount is required',
                    },
                ]}
            >
                <InputNumber
                    style={{
                        width: '100%',
                    }}
                    onChange={handleEarnestMoneyAmountChange} />
            </Form.Item>

            <Form.Item
                name='earnestMoneyDeliveryDays'
                label="Number of days to deliver earnest money"
                rules={[
                    {
                        required: true,
                        message: 'Required field',
                    },
                ]}
            >
                <Select
                    placeholder="default 2 days range 1-2 days"
                    onChange={handleEarnestMoneyDeliveryDaysChange}
                    options={[
                        {
                            value: '1 day',
                            label: '1 day',
                        },
                        {
                            value: '2 days',
                            label: '2 days',
                        },
                    ]}
                />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Space className='form-button-group'>
                    <Button type="primary" htmlType="submit">
                        Next
                    </Button>
                    <Button htmlType="button" onClick={onBack}>
                        Back
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
