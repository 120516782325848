import React from "react";

import BrandImage from '../../asset/images/HomeZephy_logo.png';

interface Props {
}

export const Brand: React.FC<Props> = ({ }) => {
    return (
        <img
        style={{
            height: '70px',
            marginTop: '5px',
        }} src={BrandImage}/>
    );
};
